import { faHouse } from '@fortawesome/pro-light-svg-icons'
import { faEdit, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { calculateFrontendTotalPrice, formatCurrencyValue } from '../../helpers/utils'
import { Address, Product, ProductOption } from '../../types'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import ProductItem from '../ProductItem/ProductItem'
import PackageItem from '../PackageItem/PackageItem'
import ProductOptionsModalContent from '../ProductOptionsModalContent/ProductOptionsModalContent'
import { useModals } from '../../hooks/useModals'
import { ExtendedProduct } from '../../services/api/orders'

interface OrderSummaryProps {
    data: {
        category: number
        address: Address
        products: Product[]
        remarks?: string
    }
    handleProductSelectionChange(products: Product[]): void
    removeProduct(product: Product): void
    onRemarkChange(remark: string): void
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
    data,
    handleProductSelectionChange,
    removeProduct,
    onRemarkChange
}: OrderSummaryProps) => {
    const { showModal, RenderModals } = useModals([
        {
            id: 'productOptions',
            comp: ProductOptionsModalContent,
            props: {
                onConfirm: (product: ExtendedProduct, selectedOptions: ProductOption[]) => {
                    let productToAdd = null

                    if (product._isPack) {
                        productToAdd = {
                            ...product,
                            // @ts-ignore
                            products: product.products?.map((p) => ({
                                ...p,
                                availableOptions: p.availableOptions?.map((o: any) => ({
                                    ...o,
                                    _isAdded: selectedOptions.includes(o)
                                }))
                            }))
                        }
                    } else {
                        productToAdd = {
                            ...product,
                            availableOptions: product.availableOptions?.map((o) => ({
                                ...o,
                                _isAdded: selectedOptions.includes(o)
                            }))
                        }
                    }

                    const newDataProducts = data.products.map((p) => {
                        if (p.id === productToAdd.id) {
                            return productToAdd
                        }
                        return p
                    })
                    handleProductSelectionChange(newDataProducts)
                }
            }
        }
    ])

    return (
        <div className='flex flex-col'>
            <RenderModals />

            <div className='rounded-xl flex-1 border-tibi-fadedPrimary border bg-white mt-4 p-4'>
                <header className='border-b pb-3 px-4 border-tibi-fadedPrimary'>
                    <h2 className='font-semibold text-xl text-black'>Controleer je bestelling</h2>
                </header>
                <div className='px-4 py-3'>
                    {data.address && (
                        <>
                            <h3 className='font-semibold text-tibi-mainHeading mb-1'>Adres</h3>
                            <div className='flex items-start my-2 pb-3'>
                                <IconWithBackground icon={faHouse} />
                                <div className='flex flex-col text-sm ml-3'>
                                    <span>
                                        {data.address.street} {data.address.houseNumber}
                                    </span>
                                    <span>
                                        {data.address.city}, {data.address.zipcode}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div>
                    <h3 className='font-semibold text-tibi-mainHeading mb-1 px-4'>Jouw producten</h3>
                    {data.products.length > 0 ? (
                        <>
                            {data.products.map((product: any, index: number) => {
                                const hasOptions =
                                    product.availableOptions?.length > 0 ||
                                    product.products?.filter((p: any) => p.availableOptions?.length > 0).length > 0

                                return (
                                    <div className='flex items-center gap-x-2' key={`${index}-${product.id}`}>
                                        {product._isPack ? (
                                            <PackageItem layout='row' a={product} />
                                        ) : (
                                            <ProductItem
                                                showHighlight={false}
                                                showOptions
                                                product={product}
                                                productInBasket={product}
                                            />
                                        )}

                                        {hasOptions ? (
                                            <button
                                                type='button'
                                                className='bg-tibi-green hover:bg-opacity-10 transition-colors duration-200 bg-opacity-5 border border-tibi-green border-opacity-10 text-tibi-green text-sm w-8 h-8 rounded-full'
                                                onClick={() => {
                                                    showModal('productOptions', { product, isPack: product._isPack })
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                        ) : (
                                            <div className='w-8'></div>
                                        )}

                                        <button
                                            onClick={() => removeProduct(product)}
                                            className='bg-tibi-primary mr-2 hover:bg-opacity-10 transition-colors duration-200 bg-opacity-5 border border-tibi-primary border-opacity-10 text-tibi-primary text-sm w-8 h-8 rounded-full'
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>
                                )
                            })}

                            <div className='px-6 text-base py-2 border-tibi-fadedPrimary text-right'>
                                <span className='font-semibold'>
                                    Totaal: {formatCurrencyValue(calculateFrontendTotalPrice(data.products))}
                                </span>
                                <span className='text-xs font-normal text-gray-500 ml-1'>ex BTW</span>
                            </div>
                        </>
                    ) : (
                        <p className='text-sm text-gray-500'>Geen producten gevonden</p>
                    )}
                </div>

                <div className='border-t p-4 border-tibi-fadedPrimary w-full V'>
                    <div className='font-semibold'>Plaats extra opmerking</div>
                    <textarea
                        className='w-full md:w-2/3 border border-tibi-fadedPrimary border-opacity-50 rounded-lg p-2 mt-2 focus:outline-none'
                        rows={3}
                        placeholder='Extra opmerking...'
                        style={{ resize: 'none' }}
                        onChange={(e) => onRemarkChange(e.target.value)}
                        defaultValue={''}
                        value={data.remarks}
                    ></textarea>
                </div>
            </div>
        </div>
    )
}

export default OrderSummary
