// import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Pack, Product, ProductOption } from '../../types'
import Modal from '../Modal/Modal'
import { formatCurrencyValue } from '../../helpers/utils'
import { ExtendedProduct } from '../../services/api/orders'

interface ProductOptionsModalContentProps {
    product?: Product | Pack
    isPack?: boolean
    onRequestClose?(): void
    onConfirm?(product: ExtendedProduct | undefined, selectedOptions: ProductOption[]): void
}

const ProductOptionsModalContent: React.FunctionComponent<ProductOptionsModalContentProps> = ({
    product,
    isPack,
    onRequestClose,
    onConfirm
}: ProductOptionsModalContentProps) => {
    const [selectedOptions, setSelectedOptions] = useState<ProductOption[]>([])

    // @ts-ignore
    let products = isPack ? product?.products : [product]

    /**
     * Initialize selected options from product options.
     * This function is called on component mount and when the product changes.
     * It sets the selected options to the options which are marked as added
     * in the product's available options.
     */
    const initializeOptions = () => {
        if (!selectedOptions.length) {
            const options = isPack
                ? // @ts-ignore
                  product?.products?.map((p) => p.availableOptions).flat()
                : // @ts-ignore
                  product?.availableOptions
            setSelectedOptions(options?.filter((option: any) => option._isAdded) || [])
        }
    }

    const handleConfirm = () => {
        onConfirm && onConfirm(product, selectedOptions)
    }

    const handleClick = (option: ProductOption) => {
        const isSelected = selectedOptions.map((o) => o.id).includes(option.id)
        if (isSelected) {
            setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption.id !== option.id))
        } else {
            setSelectedOptions([...selectedOptions, option])
        }
    }

    useEffect(() => {
        // resetProductList()
        initializeOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal buttonText='Bevestigen' onRequestClose={onRequestClose} onConfirm={handleConfirm}>
            <div className='px-4 pt-3 pb-0 bg-tibi-primary/5'>
                <div className='flex items-center gap-x-2 pb-3 border-b-2'>
                    <IconWithBackground iconName={product?.icon} />

                    <div className='text-tibi-text text-left'>
                        <p className='font-medium'>{product?.name}</p>
                        <p
                            className='text-xs text-opacity-50'
                            dangerouslySetInnerHTML={{ __html: product?.description || '' }}
                        ></p>
                    </div>
                </div>
            </div>

            <div className='text-left flex flex-col py-4 flex-grow overflow-auto'>
                <span className='font-normal px-6'>Selecteer de gewenste opties:</span>

                {products
                    .filter((p: Product) => p.availableOptions && p.availableOptions.length > 0)
                    .map((p: Product) => {
                        return (
                            <div className='flex px-6 py-3 flex-col ' key={p.id}>
                                <h3 className='font-semibold text-tibi-mainHeading'>{p.name}</h3>
                                {p.availableOptions && p.availableOptions.length > 0 && (
                                    <ul className='flex flex-col mt-4 gap-y-2'>
                                        {p.availableOptions?.map((option: ProductOption) => {
                                            const isSelected = selectedOptions.map((o) => o.id).includes(option.id)
                                            return (
                                                <li key={option.id}>
                                                    <label
                                                        htmlFor={`product-option-${option.id}`}
                                                        className='flex gap-x-3 my-0 items-start'
                                                    >
                                                        <input
                                                            type='checkbox'
                                                            name={`product-option-${option.id}`}
                                                            id={`product-option-${option.id}`}
                                                            className='hidden'
                                                            onChange={() => handleClick(option)}
                                                        />
                                                        <div
                                                            className={
                                                                'border rounded w-5 h-5 mt-0.5 flex justify-center items-center text-xs text-white ' +
                                                                (isSelected ? ' bg-tibi-green' : '')
                                                            }
                                                        >
                                                            {isSelected && <FontAwesomeIcon icon={faCheck} />}
                                                        </div>
                                                        <span>
                                                            {option.name} ({formatCurrencyValue(option.price)})
                                                        </span>
                                                    </label>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                )}
                            </div>
                        )
                    })}
            </div>
        </Modal>
    )
}

export default ProductOptionsModalContent
