import { faCalendar, faDownload, faGear, faHouse, faMessage, faShoppingBasket } from '@fortawesome/pro-light-svg-icons'
import { OrderAppointmentState, OrderPhase } from '../types'

export const DASHBOARD_ROUTES = [
    {
        to: '',
        icon: faHouse,
        displayName: 'Dashboard'
    },

    {
        to: 'orders',
        icon: faShoppingBasket,
        displayName: 'Opdrachten'
    },

    {
        to: 'messages',
        icon: faMessage,
        displayName: 'Berichten'
    },

    {
        to: 'appointments',
        icon: faCalendar,
        displayName: 'Afspraken',
        disabled: true
    },

    {
        to: 'deliveries',
        icon: faDownload,
        displayName: 'Opleveringen'
    },

    {
        to: 'settings',
        icon: faGear,
        displayName: 'Instellingen'
    }
]

export const ORDER_PHASES = [
    {
        value: OrderPhase.Active,
        hexColor: '#696969',
        label: 'In behandeling',
        showInFilter: true
    },
    {
        value: OrderPhase.Completed,
        hexColor: '#D20C60',
        label: 'Opgeleverd',
        showInFilter: true
    },
    {
        value: OrderPhase.Archived,
        hexColor: '#2EB5AD',
        label: 'Gearchiveerd',
        showInFilter: false
    }
]

export const APPOINTMENT_STATES = [
    {
        value: OrderAppointmentState.Proposed,
        hexColor: '#4B4B88',
        label: 'aangemaakt'
    },
    {
        value: OrderAppointmentState.Confirmed,
        hexColor: '#2EB5AD',
        label: 'bevestigd'
    },
    {
        value: OrderAppointmentState.Cancelled,
        hexColor: '#C52222',
        label: 'geannuleerd'
    }
]

export const DELIVERY_CATEGORIES = [
    {
        value: 'cat1',
        label: 'Categorie 1'
    },
    {
        value: 'cat2',
        label: 'Categorie 2'
    },
    {
        value: 'cat3',
        label: 'Categorie 3'
    }
]
