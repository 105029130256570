import { faEllipsis } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useModals } from '../../hooks/useModals'
import ConfirmModalContent from '../ConfirmModalContent/ConfirmModalContent'

export interface ActionOption {
    action: () => void
    text: string
    type?: 'danger' | string
}

interface ActionButtonProps {
    options: ActionOption[]
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({ options }: ActionButtonProps) => {
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)
    const clickOutsideRef = useRef<any>();

    const { showModal, RenderModals } = useModals([
        {
          id: "confirm",
          comp: ConfirmModalContent,
          props: {},
        }
      ])

    useEffect(() => {
        document.addEventListener('click', clickOutside);

        return () => {
            document.removeEventListener('click', clickOutside);
        };
    }, []);

    const clickOutside = (event: any) => {
        if (clickOutsideRef.current && !clickOutsideRef.current.contains(event.target)) {
            setActionButtonClicked(false);
        }
    };

    const handleAction = (option: ActionOption) => {
        showModal('confirm', {
            title: 'Weet je het zeker?',
            subtitle: `Weet je het zeker dat je deze afspraak wilt ${option.text}?`,
            primaryButtonText: option.text,
            primaryButtonAction: option.action,
            secondaryButtonText: 'Nee, sluiten',
            // onRequestClose: () => {},
            onConfirm: () => option.action()
        })
    }

    if (!options.length) {
        return null
    }

    return (
        <div className='relative'>
            <RenderModals />

            <button type='button' onClick={() => setActionButtonClicked(!actionButtonClicked)} ref={clickOutsideRef}>
                <FontAwesomeIcon icon={faEllipsis} />
            </button>
            {actionButtonClicked &&
            <div className='border rounded-md bg-white cursor-pointer z-10 absolute mt-2 right-0'>
                {options.map((option: ActionOption, i: number) => {
                    const textColor = option.type === 'danger' ? 'text-red-500' : 'text-tibi-text'

                    return (
                        <div key={i}>
                            <p className={`border px-8 py-2 hover:bg-tibi-textLight hover:bg-opacity-10 capitalize ${textColor}`}
                               onClick={() => handleAction(option)}
                            >
                                {option.text}
                            </p>
                        </div>
                    )
                })
                }
            </div>
            }
        </div>
    )
}

export default ActionButton
