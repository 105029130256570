import './index.scss'
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom'
import Dashboard from './pages/Dashboard/Dashboard'
import Login from './pages/Login'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import Orders from './pages/Orders/Orders'
import DashboardIndex from './pages/DashboardIndex/DashboardIndex'
import Appointments from './pages/Appointments/Appointments'
import NewOrder from './components/NewOrder/NewOrder'
import { getCurrentUser } from 'aws-amplify/auth'
import { Authenticator } from '@aws-amplify/ui-react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'

import 'react-tooltip/dist/react-tooltip.css'
import OrderOverview from './components/OrderOverview/OrderOverview'
import Messages from './pages/Messages/Messages'
import { TibiContextProvider } from './context/ContextProvider'
import Deliveries from './pages/Deliveries/Deliveries'

// load light icon library global
library.add(fal)

const ENV = process.env.NODE_ENV || 'development'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const authCheck = async () => {
    try {
        const user = await getCurrentUser()
        if (!user) return redirect('/login')
        return user
    } catch (error: any) {
        return redirect('/login')
    }
}

const router = createBrowserRouter([
    {
        path: '*',
        element: (
            <div className='flex flex-col w-screen h-screen items-center justify-center bg-tibi-blushPrimary'>
                <div className='flex items-center space-y-2 flex-col border border-tibi-primary rounded-xl shadow-sm p-8 px-12 bg-white'>
                    <h2 className='text-3xl font-semibold'>404</h2>
                    <h1 className='text-tibi-primary font-semibold'>This page does not exist</h1>
                    <a href='/dashboard' className='underline text-tibi-linkColor'>
                        Back to dashboard
                    </a>
                </div>
            </div>
        )
    },
    {
        path: '/',
        loader: () => {
            return redirect('/dashboard')
        }
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/dashboard',
        loader: authCheck,
        element: <Dashboard />,
        children: [
            {
                //Default route for dashboard
                path: '',
                element: <DashboardIndex />
            },
            {
                path: 'orders',
                element: <Orders />,
                children: [
                    {
                        //Default route for dashboard
                        path: '',
                        element: <OrderOverview />
                    },
                    {
                        //Default route for dashboard
                        path: ':phase',
                        element: <OrderOverview />
                    }
                ]
            },

            {
                path: 'new-order',
                element: <NewOrder />
            },

            {
                path: 'appointments',
                element: <Appointments />
            },
            {
                path: 'messages',
                element: <Messages />
            },
            {
                path: 'deliveries',
                element: <Deliveries />
            }
        ]
    }
])

root.render(
    // <React.StrictMode>
    <Authenticator.Provider>
        <TibiContextProvider storageKey={`TIBIMEDIA_${ENV}`}>
            <RouterProvider router={router} />
        </TibiContextProvider>
    </Authenticator.Provider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
