import { Suspense } from 'react'
// Import necessary FontAwesomeIcon component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxTaped, IconName } from '@fortawesome/pro-light-svg-icons'

const DynamicFaIcon = ({ iconName, ...restProps }: { iconName?: string; [key: string]: any }) => {
    console.log('iconName', iconName)
    const iconNameParsed: IconName | undefined = iconName?.replace('fa-', '') as IconName
    return iconNameParsed ? (
        <Suspense fallback={<FontAwesomeIcon icon={faBoxTaped} />}>
            <FontAwesomeIcon icon={['fal', iconNameParsed]} {...restProps} />
        </Suspense>
    ) : null
}

export default DynamicFaIcon
