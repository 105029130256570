import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import IconWithBackground from '../IconWithBackground/IconWithBackground' // Import the IconWithBackground component if not already imported
import { formatCurrencyValue } from '../../helpers/utils'

interface PackageItemProps {
    a: {
        icon?: any // Replace with the actual type of the icon prop
        name: string
        description: string
        price: number // Replace with the actual type of the price prop
        products: any[]
    }
    layout: 'row' | 'block'
    inBasket?: boolean
    addProductToBasket?: (product: any, isPack: boolean) => void // Replace 'any' with the actual type of the product
    removeProductFromBasket?: (product: any, isPack: boolean) => void // Replace 'any' with the actual type of the product
}

const PackageItem: React.FC<PackageItemProps> = ({
    a,
    inBasket,
    layout = 'block',
    addProductToBasket,
    removeProductFromBasket
}) => {
    const selectedOptions = a.products
        .map((p: any) => p.availableOptions)
        .flat()
        .filter((o: any) => o._isAdded)

    return (
        <li
            className={`flex flex-grow ${
                layout === 'row' ? 'flex-row py-3 border-b border-b-tibi-fadedPrimary last:border-b-0' : 'flex-col'
            } items-center flex-1`}
        >
            <div
                className={`px-4 w-full h-full flex flex-wrap ${
                    layout === 'row' ? 'flex-row space-x-3 justify-between' : 'pt-4 flex-col space-y-3 text-center'
                } items-center`}
            >
                <div className={layout === 'row' ? 'w-full flex justify-between' : ''}>
                    <div
                        className={`flex ${
                            layout === 'row' ? 'flex-row items-start' : 'flex-col gap-y-2 items-center'
                        }`}
                    >
                        <IconWithBackground iconName={a.icon} />
                        <div className='flex flex-col mx-3'>
                            <span className='font-semibold block text-base my-1'>{a.name}</span>
                            <span
                                className='text-left text-sm text-gray-700'
                                dangerouslySetInnerHTML={{ __html: a.description }}
                            ></span>
                        </div>
                    </div>

                    <span className='block px-4 text-sm mt-1'>{formatCurrencyValue(a.price)}</span>
                </div>

                <div className='w-full pl-12 pr-7 mt-2'>
                    {selectedOptions?.map((availableOption: any, i: number) => {
                        return (
                            <div key={i} className='text-xs text-gray-500 flex justify-between gap-x-2'>
                                <span>+ {availableOption.name}</span>
                                <span>{formatCurrencyValue(availableOption.price)}</span>
                            </div>
                        )
                    })}
                </div>
            </div>

            {addProductToBasket && removeProductFromBasket && (
                <button
                    onClick={() => (inBasket ? removeProductFromBasket(a, true) : addProductToBasket(a, true))}
                    className={`text-tibi-green bg-tibi-green ${
                        inBasket ? 'bg-opacity-10' : 'bg-opacity-0'
                    } border-t text-sm py-4 border-tibi-fadedPrimary transition-colors duration-300 w-full space-x-2 mt-4 flex items-center justify-center hover:bg-opacity-10`}
                >
                    <FontAwesomeIcon
                        icon={inBasket ? faCheckCircle : faPlusCircle}
                        className={`${inBasket && ''} text-2xl`}
                    />
                    <span className='font-semibold'>{inBasket ? 'GESELECTEERD' : 'SELECTEER'}</span>
                </button>
            )}
        </li>
    )
}

export default PackageItem
