import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { APPOINTMENT_STATES } from '../../helpers/constants'
import { OrderAppointmentState } from '../../types'

interface AppointmentStateLabelProps {
    state: OrderAppointmentState | undefined
}

const AppointmentStateLabel: React.FunctionComponent<AppointmentStateLabelProps> = ({
    state
}: AppointmentStateLabelProps) => {
    const labelConfig = APPOINTMENT_STATES.find((app) => app.value === state)
    return (
        <div className='space-x-2 flex items-center'>
            {/* STATUS */}
            <FontAwesomeIcon className='text-xs' style={{ color: labelConfig?.hexColor }} icon={faCircle} />
            <span className='font-medium text-sm'>{labelConfig?.label}</span>
        </div>
    )
}

export default AppointmentStateLabel
