import React from 'react'
import { faHouse } from '@fortawesome/pro-light-svg-icons'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import { OrderMessageThread } from '../../types'
import { formatDate, formatTime, isToday, isYesterday } from '../../helpers/utils'
import { NavLink } from 'react-router-dom'

export interface MessageItemProps {
    data: OrderMessageThread
    isSelected?: boolean
}

const MessageItem: React.FunctionComponent<MessageItemProps> = ({ data, isSelected }: MessageItemProps) => {
    const orderPerson = data.concerns

    const getTimeOfLastMessage = () => {
        if (!data.lastMessage?.date) {
            return null
        }

        if (isToday(data.lastMessage.date)) {
            return formatTime(data.lastMessage.date)
        } else if (isYesterday(data.lastMessage.date)) {
            return 'Gisteren'
        } else {
            return formatDate(data.lastMessage.date)
        }
    }

    return (
        <NavLink
            to={`/dashboard/messages?selectedThread=${data.id}`}
            className={`${
                isSelected ? 'bg-tibi-linkColor bg-opacity-5' : 'bg-white'
            } flex w-full hover:bg-gray-50 justify-between items-center border-t first:border-t-0 border-tibi-fadedPrimary flex-wrap gap-y-px px-2`}
        >
            <div className='w-1/6 flex justify-center'>
                <IconWithBackground icon={faHouse} />
            </div>

            <div className='w-5/6 relative'>
                <div className='flex flex-col flex-grow flex-shrink overflow-hidden pt-6 pb-4 px-2'>
                    <h3 className='font-medium truncate max-w-64'>{data.order.title}</h3>
                    <span className='text-tibi-text text-sm'>
                        {data.order.address}, {data.order.zipcode} {data.order.city}
                    </span>
                    <div className='text-sm truncate font-semibold'>
                        <span className='text-tibi-text'>betreffende </span>
                        <span className='text-tibi-linkColor'>{orderPerson}</span>
                    </div>
                    <p className='text-tibi-text text-xs truncate max-w-64 mt-2 italic'>
                        {data.lastMessage?.message || '<Geen bericht>'}
                    </p>
                </div>

                <div className='absolute right-1 top-1'>
                    <span className='text-xs opacity-50'>{data.lastMessage?.date && getTimeOfLastMessage()}</span>
                </div>

                {data.unreadCount !== undefined && data.unreadCount > 0 && (
                    <span className='bg-tibi-primary text-sm w-6 h-6 flex items-center justify-center rounded-full text-white'>
                        {data.unreadCount}
                    </span>
                )}
            </div>
        </NavLink>
    )
}

export default MessageItem
