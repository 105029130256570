import React from 'react'
import SearchInput from '../SearchInput/SearchInput'
import IconButton from '../Button/IconButton'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import MessageItem from '../MessageItem/MessageItem'
import { OrderMessageThread } from '../../types'
import DefaultLoader from '../Loaders/DefaultLoader'

interface MessagesListProps {
    messageThreads: OrderMessageThread[]
    selectedThread: number | null
    setSearchString: (query: string) => void
    loading: boolean
    className?: string
}

const MessagesList: React.FunctionComponent<MessagesListProps> = ({
    messageThreads,
    selectedThread,
    setSearchString,
    loading,
    className = ''
}: MessagesListProps) => {
    const handleSearchChange = (query: string) => {
        setSearchString(query)
    }

    return (
        <div
            className={
                'w-full flex-grow flex flex-col overflow-hidden border rounded-t-md lg:rounded-tr-none rounded-bl-md border-tibi-fadedPrimary ' +
                className
            }
        >
            <div className='p-3 bg-white rounded-tl-md'>
                <SearchInput placeholder='Zoek opdracht of locatie' onSearchChange={handleSearchChange} />
            </div>

            <div className='border-t border-b border-tibi-fadedPrimary overflow-y-auto w-full min-h-[656px] flex-grow bg-white'>
                {loading ? (
                    <div className='w-full h-full flex items-center justify-center py-8'>
                        <DefaultLoader />
                    </div>
                ) : messageThreads.length ? (
                    messageThreads?.map((thread: OrderMessageThread) => {
                        return <MessageItem isSelected={selectedThread === thread.id} key={thread.id} data={thread} />
                    })
                ) : (
                    <div className='w-full h-full flex items-center justify-center py-8'>Geen berichten gevonden</div>
                )}
            </div>

            <div className='bg-white w-full h-[72px] flex items-center justify-end p-3 rounded-bl-md'>
                <IconButton icon={faPlus} onClick={() => {}} disabled={true} />
            </div>
        </div>
    )
}

export default MessagesList
