import { FieldValues } from 'react-hook-form'
import { InlineResponse200, OrderPhase, Product, ProductOption } from '../../types'
import { get, post, put } from './api'

export interface ExtendedProductOption extends ProductOption {
    _isAdded?: boolean
}

export interface ExtendedProduct extends Product {
    _isPack?: boolean
    availableOptions?: ExtendedProductOption[]
    products?: ExtendedProduct[]
}

export const getOrders = async (query?: {
    searchString?: string
    phase?: OrderPhase | undefined
    page?: number
    pageSize?: number
}): Promise<InlineResponse200> => {
    const { data } = await get('/orders', {
        params: {
            ...query
        }
    })

    return data
}

export const retrieveAddress = async (params: FieldValues) => {
    const { data } = await put('/orders/retrieve-address', {
        data: params
    })

    return data
}

export const calculateTotalOrderPrice = async (params: {
    zipcode: string
    houseNumber: number
    extension?: string
    category: number
    packs?: number[]
    products?: number[]
    productOptions?: ExtendedProductOption[]
}) => {
    const { data } = await put('/orders/calculate', {
        data: params
    })

    return data
}

export const createOrder = async (params: {
    category: number
    zipcode: string
    houseNumber: number
    extension: string
    surface?: string
    packs?: number[]
    products?: number[]
    productOptions?: number[]
    remarks?: string
}) => {
    const { data } = await post('/orders', {
        data: params
    })

    return data
}

export const getMappedProductOptions = (data: ExtendedProduct[]) => {
    const opts: ExtendedProduct[] =
        data.length > 0
            ? data
                  .map((a: ExtendedProduct) => {
                      if (a._isPack) {
                          return a.products || []
                      } else {
                          return a
                      }
                  })
                  .flat()
            : []

    const allOptions =
        opts.length > 0 &&
        opts
            .filter((a) => a.availableOptions)
            .map((a: any) => a.availableOptions)
            .flat()
            .filter((a: any) => a._isAdded) // filter undefined values

    // get all product options that are added
    const productOptions = allOptions && allOptions.length > 0 && allOptions.map((a: any) => a.id)

    return productOptions || []
}
