import { faArrowRight, faHouse, faPaintBrush } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { getCategoryProducts } from '../../services/api/categories'
import { Address, Pack, Product, ProductOption } from '../../types'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import { calculateTotalOrderPrice, ExtendedProduct, getMappedProductOptions } from '../../services/api/orders'
import {
    calculateFrontendTotalOptionsPrice,
    calculateFrontendTotalPrice,
    formatCurrencyValue
} from '../../helpers/utils'
import PopularPackages from './PopularPackages'
import CategoryHeader from '../CategoryHeader'
import DefaultLoader from '../Loaders/DefaultLoader'
import ProductItem from '../ProductItem/ProductItem'
import Button from '../Button/Button'
import ProductOptionsModalContent from '../ProductOptionsModalContent/ProductOptionsModalContent'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { useModals } from '../../hooks/useModals'

interface ProductSelectionProps {
    selectedProducts: Product[]
    address: Address
    categoryId: number
    onSelectionChange(products: Product[]): void
    onNext(): void
}
const ProductSelection: React.FunctionComponent<ProductSelectionProps> = ({
    selectedProducts,
    address,
    categoryId,
    onSelectionChange,
    onNext
}: ProductSelectionProps) => {
    const [basket, setBasket] = useState<any[]>(selectedProducts ? selectedProducts : [])
    const [productList, setProductList] = useState<any>({})
    const [packageList, setPackageList] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(true) // set default on true

    console.log('basket', basket)

    const { showModal, RenderModals } = useModals([
        {
            id: 'productOptions',
            comp: ProductOptionsModalContent,
            props: {
                onConfirm: (product: ExtendedProduct, selectedOptions: ProductOption[]) => {
                    let productToAdd = null

                    if (product._isPack) {
                        productToAdd = {
                            ...product,
                            // @ts-ignore
                            products: product.products?.map((p) => ({
                                ...p,
                                availableOptions: p.availableOptions?.map((o: any) => ({
                                    ...o,
                                    _isAdded: selectedOptions.includes(o)
                                }))
                            }))
                        }
                    } else {
                        productToAdd = {
                            ...product,
                            availableOptions: product.availableOptions?.map((o) => ({
                                ...o,
                                _isAdded: selectedOptions.includes(o)
                            }))
                        }
                    }

                    updateBasket(productToAdd, productToAdd._isPack)
                }
            }
        }
    ])

    useEffect(() => {
        let ctrl: AbortController = new AbortController()
        const fetchProductList = async () => {
            setLoading(true)
            try {
                const data = await getCategoryProducts({ categoryUUID: categoryId })
                setProductList(data.products)
                setPackageList(
                    data.packs.map((p: any) => {
                        return { ...p, _isPack: true }
                    })
                )
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        fetchProductList()
        return () => ctrl.abort()
    }, [categoryId])

    /**
     * When basket is changed, re-calculate total
     */
    useEffect(() => {
        let ctrl: AbortController = new AbortController()
        const calculateOrderTotal = async () => {
            // Callback to parent with product selection
            onSelectionChange(basket)
            try {
                // get all product options that are added
                const productOptions = getMappedProductOptions(basket)

                const total = await calculateTotalOrderPrice({
                    zipcode: address.zipcode,
                    category: Number(categoryId),
                    extension: address.extension,
                    houseNumber: Number(address.houseNumber),
                    products: basket.filter((a) => !a._isPack).map((a) => a.id),
                    packs: basket.filter((a) => a._isPack).map((a) => a.id),
                    productOptions: productOptions || []
                })
                console.log('totalPrice from BE', total)
                // Todo: Set totalPrice state variable here (response is not correct yet)
            } catch (e) {
                console.error(e)
            }
        }
        calculateOrderTotal()
        return () => ctrl.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basket])

    const updateBasket = (addedProduct: any, isPack = false) => {
        const inBasket = basket.some((a) => a.id === addedProduct.id)
        if (inBasket) {
            setBasket((prevState: React.ComponentState) => {
                const newBasket = [...prevState]
                const idx = newBasket.findIndex((a) => a.id === addedProduct.id)
                idx >= 0 && newBasket.splice(idx, 1, addedProduct)
                return newBasket
            })
        } else {
            setBasket((prevState: React.ComponentState) => {
                let newBasket = [...prevState]

                if (isPack) {
                    // Remove pack if there is already a pack in the basket (only one allowed)
                    newBasket = newBasket.filter((p) => !p._isPack)
                    // Filter out existing products in the basket that are also in the pack
                    // Existing separate products are removed from the basket in favour of the pack
                    newBasket = newBasket.filter(
                        (p) => !p._isPack && !addedProduct.products.map((a: Product) => a.id).includes(p.id)
                    )
                }
                newBasket.push({ ...addedProduct, _isPack: isPack })
                return newBasket
            })
        }
    }

    const addProductToBasket = (addedProduct: any, isPack = false) => {
        const hasOptions =
            addedProduct.availableOptions?.length > 0 ||
            (isPack && addedProduct.products.filter((p: any) => p.availableOptions?.length > 0).length > 0)

        if (hasOptions) {
            showModal('productOptions', { product: addedProduct, isPack: isPack })
        } else {
            updateBasket(addedProduct, isPack)
        }
    }

    const removeProductFromBasket = (targetProduct: any, isPack = false) => {
        setBasket((prevState: React.ComponentState) => {
            // return prevState.filter((curr: any) => curr.id !== targetProduct.id && curr._isPack !== targetProduct._isPack);
            const temp = [...prevState]
            const idx = temp.findIndex((a) => a.id === targetProduct.id && a._isPack === isPack)
            idx >= 0 && temp.splice(idx, 1)
            return temp
        })
    }

    const alreadyAddedFromPack = (product: Product) => {
        const packProducts = basket
            .filter((p: ExtendedProduct) => p._isPack) // filter packs only
            .flatMap((p: Pack) => p.products?.map((p: Product) => p.id)) // flatten products

        return packProducts.includes(product.id)
    }

    /**
     * Opens the product options modal for the given product.
     * @param {object} product - The product to edit options for.
     */
    const editOptions = (product: any) => {
        showModal('productOptions', { product, isPack: product._isPack })
    }

    return (
        <div className='flex mt-4 flex-wrap gap-4'>
            <RenderModals />

            <div className='flex flex-col flex-1'>
                {loading ? (
                    <DefaultLoader />
                ) : (
                    <>
                        <PopularPackages
                            packages={packageList}
                            selectedProducts={selectedProducts}
                            onSelectionChange={onselectionchange}
                            addProductToBasket={addProductToBasket}
                            removeProductFromBasket={removeProductFromBasket}
                        />
                        <div className='mt-4'>
                            <CategoryHeader title='Losse producten' />
                            <div className='rounded-xl flex-1 border-tibi-fadedPrimary border bg-white mt-2'>
                                {productList.map((product: Product) => {
                                    const productInBasket = basket.find(
                                        (curr: any) => curr._isPack === false && curr.id === product.id
                                    )

                                    const isDisabled = alreadyAddedFromPack(product)

                                    return (
                                        <ProductItem
                                            key={`product-${product.id}`}
                                            product={product}
                                            showOptions={false}
                                            disabled={isDisabled}
                                            productInBasket={productInBasket}
                                            addProductToBasket={addProductToBasket}
                                            removeProductFromBasket={removeProductFromBasket}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className='w-full lg:w-72 lg:mt-[2.72rem]'>
                <div className='w-72 rounded-xl flex flex-col border-tibi-fadedPrimary border bg-white self-start top-4 sticky'>
                    <div className='px-3 py-2 flex-shrink-0 flex-grow-0'>
                        <h2 className='font-semibold text-base text-black mb-2'>Samenvatting opdracht</h2>
                        {address && (
                            <div className='flex items-start'>
                                <IconWithBackground icon={faHouse} size='small' />
                                <div className='flex flex-col ml-2 text-xs'>
                                    <span>
                                        {address.street} {address.houseNumber}
                                    </span>
                                    <span>
                                        {address.city}, {address.zipcode}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='overflow-y-auto w-full flex-grow'>
                        {basket.map((product: any) => {
                            const hasOptions =
                                product.availableOptions?.length > 0 ||
                                product.products?.filter((p: any) => p.availableOptions?.length > 0).length > 0
                            const numOptions =
                                product.availableOptions?.filter((o: any) => o._isAdded).length ||
                                product.products
                                    ?.map((p: any) => p.availableOptions?.filter((o: any) => o._isAdded).length)
                                    .reduce((a: any, b: any) => a + b, 0)

                            let totalPrice = null

                            if (product._isPack) {
                                const totalOptionsPrice = product.products
                                    .map((p: any) => calculateFrontendTotalOptionsPrice([p]))
                                    .reduce((a: any, b: any) => a + b, 0)
                                totalPrice = formatCurrencyValue(product.price + totalOptionsPrice)
                            } else {
                                totalPrice = formatCurrencyValue(
                                    product.price + calculateFrontendTotalOptionsPrice([product])
                                )
                            }

                            return (
                                <div
                                    className='flex items-center border-t py-2 px-3 border-b-tibi-fadedPrimary'
                                    key={`${product.id}-${product.name}`}
                                >
                                    <IconWithBackground icon={faPaintBrush} size='small' />
                                    <div className='ml-2'>
                                        <h4 className='font-semibold text-xs'>
                                            {product.title || product.name}

                                            {numOptions > 0 && (
                                                <span className='text-tibi-textLight text-opacity-80'>
                                                    {' '}
                                                    (+{numOptions} optie{numOptions > 1 ? 's' : ''})
                                                </span>
                                            )}
                                        </h4>
                                        <span className='text-xs'>{totalPrice}</span>
                                    </div>

                                    {hasOptions && (
                                        <button
                                            type='button'
                                            className='w-[30px] h-[30px] flex items-center justify-center border border-tibi-green bg-tibi-green bg-opacity-20 rounded-full text-sm text-tibi-green ml-auto'
                                            onClick={() => editOptions(product)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <div className='border-t px-3 text-sm py-3 flex-shrink-0 border-tibi-fadedPrimary flex flex-col'>
                        <span className='font-semibold mb-3 block'>
                            Totaal: {formatCurrencyValue(calculateFrontendTotalPrice(basket))}
                            <span className='text-xs font-normal text-gray-500 ml-1'>ex BTW</span>
                        </span>
                        <Button onClick={onNext}>
                            Volgende stap <FontAwesomeIcon icon={faArrowRight} className='ml-2' />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductSelection
